import React,{useState,useContext,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import OrderContext from '../../../store/order-context'
import classes from './RomanPseudoroman.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const RomanPseudoroman = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState('');
  const [quantity, setQuantity]=useState('1');
  const [meterPriceFabric,setMeterPriceFabric]=useState('');
  
  const [meterPriceMechanism, setMeterPriceMechanism]=useState('');
  const [windowWidth, setWindowWidth]=useState('');
  const [windowHeight, setWindowHeight]=useState('');
  const [typeSelection, setTypeSelection]=useState('Roman');
  const [installPosition, setInstallPosition]=useState('outside');

  //roman
  const [mechanism, setMechanism] =useState('dexia');
  const [mechanismColor, setMechanismColor] = useState('leuko');
  const [romanWidth, setRomanWidth]=useState('');
  const [romanHeight, setRomanHeight]=useState('');
  const [fabricMeters, setFabricMeters]=useState('');
  const [liningSelection, setLiningSelection]=useState('without');
  const [secondSku, setSecondSku]=useState('');
  const [secondMeterPriceFabric, setSecondMeterPriceFabric]=useState(0);
  const [fodra, setFodra]=useState("oxi");
  const [timiRafis, setTimiRafis]=useState("");

  //pseudoRoman
  const [rodWidth, setRodWidth]=useState('');
  const [pseudoromanWidth, setPseudoromanWidth]=useState('');
  const [pleats, setPleats]=useState('');

  const [dimensions,setDimensions]=useState("-");

  const [totalPrice, setTotalPrice]=useState('');
  const [installation, setInstallation]=useState('oxi');
  const [manualInstallPrice, setManualInstallPrice] = useState('');
  const [installPrice, setInstallPrice]=useState('0');

  const [errorMessage, setErrorMessage] =useState('');

  const [isLoaded, setIsLoaded] = useState(false);

  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');

  const [freeText, setFreeText]=useState('');
  const [options,setOptions]=useState({id:'',label:''});

  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      console.log(error);
    });

  },[isLoaded])

    const handleFocus = (event) => {
    const { name, value } = event.target;
    if(value){
    const inputValue = parseFloat(value);


    if (name === 'windowWidth') {
      setWindowWidth(inputValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(inputValue);
    } else if (name === 'romanWidth') {
      setRomanWidth(inputValue);
    } else if (name === 'romanHeight') {
      setRomanHeight(inputValue);
    } else if (name === 'fabricMeters') {
      setFabricMeters(inputValue);
    } else if (name === 'rodWidth') {
      setRodWidth(inputValue);
    }
  }
  }

    const handleBlur = (event) => {
    const { name, value } = event.target;
    if(value){
    const inputValue = parseFloat(value);
    const formattedValue = inputValue.toFixed(3) + 'm';

    if (name === 'windowWidth') {
      setWindowWidth(formattedValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(formattedValue);
    } else if (name === 'romanWidth') {
      setRomanWidth(formattedValue);
    } else if (name === 'romanHeight') {
      setRomanHeight(formattedValue);
    } else if (name === 'fabricMeters') {
      setFabricMeters(formattedValue);
    } else if (name === 'rodWidth') {
      setRodWidth(formattedValue);
    }
  }
  }

  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }

  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const skuChangeHandler = (event)=>{
    setSku(event.target.value);
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const meterPriceFabricChangeHandler = (event)=>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setMeterPriceFabric(value);
    }
  }
  const meterPriceMechanismChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setMeterPriceMechanism(value);
    }
  }
  const mechanismSelectionHandler = (event) =>{
    setMechanism(event.target.value);
  }
  const mechanismColorSelectionHandler = (event) =>{
    setMechanismColor(event.target.value);
  }
  const windowWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowWidth(value);
    }
  }
  const windowHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowHeight(value);
    }
  }
  const selectTypeHandler = (event) =>{
    setTotalPrice('');
    setTypeSelection(event.target.value);
  }

  //roman
  const romanWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRomanWidth(value);
    }
  }
  const romanHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRomanHeight(value);
    }
  }
  const installPositionHandler = (event) =>{
    setInstallPosition(event.target.value);
  }
  const fabricMetersChangeHandler = (event) => {
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setFabricMeters(value);
    }
  }
  const liningSelectionHandler = (event) =>{
    setLiningSelection(event.target.value)
  }
  const fodraHandler = (event) =>{
    setFodra(event.target.value)
  }
  const timiRafisChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setTimiRafis(value);
    }
  }
  //pseudoRoman
  const rodWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRodWidth(value);
    }
  }
  const pleatsChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setPleats(value);
    }
  }
  const freeTextChangeHandler = (event) =>{
    setFreeText(event.target.value);
  }
  const secondSkuChangeHandler = (event) =>{
    setSecondSku(event.target.value);
  }
  const secondMeterPriceFabricChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
   
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
       setSecondMeterPriceFabric(value)
    }
  }
  useEffect(()=>{
    let mechanismPrice = 0;
    if (meterPriceMechanism){
      mechanismPrice = parseFloat(meterPriceMechanism);
    }
    if (typeSelection === "Roman"){
      let minMechanismPrice=Math.max(parseFloat(romanWidth),1);
      console.log(parseFloat(fabricMeters)*12);
      let minSize = Math.max((parseFloat(romanWidth)*parseFloat(romanHeight)),1)
        if (liningSelection === "without"){
          setTotalPrice(Math.round(((minSize*26)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="with"){
          setTotalPrice(Math.round(((minSize*32)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)))+(parseFloat(fabricMeters)*12)+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="lepto-without"){
          setTotalPrice(Math.round(((minSize*40)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if (liningSelection ==="lepto-with"){
          console.log(parseFloat(fabricMeters))
          setTotalPrice(Math.round(((minSize*48)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)))+(parseFloat(fabricMeters)*12)+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if (liningSelection ==="dyo-simple"){
          setTotalPrice(Math.round(((minSize*50)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+parseFloat(secondMeterPriceFabric)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="stathero-without"){
          setTotalPrice(Math.round(((minSize*34)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+(parseFloat(secondMeterPriceFabric)*1.2)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="stathero-with"){
          setTotalPrice(Math.round(((minSize*38)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+(parseFloat(secondMeterPriceFabric)*1.2)))+(parseFloat(fabricMeters)*12)+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="lepto-isio-without"){
          setTotalPrice(Math.round(((minSize*48)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+(parseFloat(secondMeterPriceFabric)*1.2)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="lepto-isio-with"){
          setTotalPrice(Math.round(((minSize*56)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+(parseFloat(secondMeterPriceFabric)*1.2)))+(parseFloat(fabricMeters)*12)+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="dyo-isia"){
          setTotalPrice(Math.round(((minSize*60)+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+(parseFloat(secondMeterPriceFabric)*1.2)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
        }else if(liningSelection ==="custom"){
          if(fodra==="oxi"){
            setTotalPrice(Math.round(((minSize*parseFloat(timiRafis))+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+parseFloat(secondMeterPriceFabric)))+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
          }else if(fodra==="nai"){
            setTotalPrice(Math.round(((minSize*parseFloat(timiRafis))+(parseFloat(fabricMeters)*(parseFloat(meterPriceFabric)+parseFloat(secondMeterPriceFabric)))+(parseFloat(fabricMeters)*12)+(minMechanismPrice*mechanismPrice))*quantity*100)/100);
          }
        }

    }else if(typeSelection === "Pseudoroman"){
      setTotalPrice(Math.round((parseFloat(fabricMeters)*parseFloat(meterPriceFabric)+30)*quantity*100)/100);
    }
  },[typeSelection,romanWidth,romanHeight,fabricMeters,meterPriceFabric,meterPriceMechanism,liningSelection,fodra,timiRafis,secondMeterPriceFabric,timiRafis, quantity])

 
  useEffect(()=>{
    setSecondMeterPriceFabric(0);
    setSecondSku('');
  },[typeSelection,liningSelection])

  const installationHandler = (event) =>{
    setInstallation(event.target.value);
  }
 const installationPriceChangeHandler = (event) =>{
   
   const regex = /^\d*\.?\d{0,3}$/;
   let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    if (regex.test(value) || value === '') {
      setInstallPrice(value);
      setManualInstallPrice(value);
    }
 }
 const dimensionsCalculate = event =>{
   setDimensions( parseFloat(rodWidth)*100 + "x" + parseFloat(romanWidth)*100 );
 }
 const handleSaveClick = () =>{
  if ( companySelection.length>0&&sku!=''&&meterPriceFabric!=''&&
    windowWidth!=''&&windowHeight!=''&& !buttonDisabled){
    setButtonDisabled(true)
    const config = {
      headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    let installPositionString=''
        switch(installPosition){
          case "outside":
            installPositionString = "Έξω";
            break;
          case "inside":
            installPositionString = "Μέσα";
            break;
          default:
            installPositionString = "";
            break;
        }
    let mechanismString=''
    switch(mechanism){
        case "right":
          mechanismString = "Δεξιά";
          break;
        case "left":
          mechanismString = "Αριστερά";
          break;
        default:
          mechanismString = "Δεξιά";
          break;
    }

    let mechanismColorString=''
    switch(mechanismColor){
      case "0":
        mechanismColorString = "0";
        break;
      case "leuko":
        mechanismColorString = "Λευκό";
        break;
      case "inox":
        mechanismColorString = "Inox";
        break;
      default:
        mechanismColorString = "0";
        break;
    }

    let liningString=''
    switch(liningSelection){
        case "with":
          liningString = "Σταθερό Ύφασμα - ΑΠΛΟ ΜΕ ΦΟΔΡΑ";
          break;
        case "without":
          liningString = "Σταθερό ύφασμα - ΑΠΛΟ ΧΩΡΙΣ ΦΟΔΡΑ";
          break;
        case "lepto-without":
          liningString = "Λεπτό Ύφασμα - ΑΠΛΟ ΧΩΡΙΣ ΦΟΔΡΑ";
        case "lepto-with":
          liningString = "Λεπτό Ύφασμα - ΑΠΛΟ ΜΕ ΦΟΔΡΑ";
          break;
        case "dyo-simple":
          liningString = "Δύο λεπτά υφάσματα μαζί - ΑΠΛΟ";
          break;
        case "stathero-without":
          liningString = "Σταθερό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΧΩΡΙΣ ΦΟΔΡΑ";
          break;
        case "stathero-with":
          liningString = "Σταθερό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΜΕ ΦΟΔΡΑ";
          break;
        case "lepto-isio-without":
          liningString = "Λεπτό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΧΩΡΙΣ ΦΟΔΡΑ";
          break;
        case "lepto-isio-with":
          liningString = "Λεπτό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΜΕ ΦΟΔΡΑ";
          break;
        case "dyo-isia":
          liningString = "Δύο λεπτά υφάσματα μαζί - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm";
          break;
        case "custom":
          liningString = "Άλλη ραφή"
          break;
    }

    const productOptions = JSON.stringify({
        quantity: quantity,
        installPosition: installPositionString,
        meterPriceFabric: meterPriceFabric,
        meterPriceMechanism: meterPriceMechanism,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        mechanism: mechanismString,
        mechanismColor: mechanismColorString,
        romanWidth: romanWidth,
        romanHeight: romanHeight,
        fabricMeters: fabricMeters,
        liningSelection: liningString,
        secondSku: secondSku,
        secondMeterPriceFabric: secondMeterPriceFabric,
        rodWidth: rodWidth,
        pseudoromanWidth: pseudoromanWidth,
        pleats: pleats,
        dimensions: dimensions,
        totalPrice: totalPrice,
        discount: discount,
        discountPrice: discount ? discountPrice:'',
        totalPriceDiscount: discount ? totalPriceDiscount:'',
        installation: installation,
        installPrice: installation==='nai'?installPrice:'',
        fodra: (liningSelection === "custom") ? fodra:'',
        timiRafis: (liningSelection === "custom") ? timiRafis:'',
        freeText: freeText
      })
    let typeString ='';
    switch(typeSelection){
    case 'Roman':
      typeString="Ρόμαν"
      break;
    case 'Pseudoroman':
      typeString="Ψευτορόμαν"
      break;
    }
    axios.post('https://api.orders.e-interior.gr/api/product/create',{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
      order_id: props.OrderId?props.OrderId:cookies.OrderId,
      product_type: typeString,
      manufacturer: companySelection[0].label,
      code: sku,
      product_options: productOptions,
      status: "submitted",
      ordered_at: null
      },
      config
    )
    .then(function (response) {
      setButtonDisabled(false)
      console.log(response.data);
      props.onSave({data:response.data})

    })
    .catch(function (error) {
      setButtonDisabled(false)
      console.log(error);
    });
  }else{
    setErrorMessage('Λείπουν υποχρεωτικά πεδία');
  }
  }
useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

    useEffect (()=>{
      if (manualInstallPrice>0){
        setInstallPrice(manualInstallPrice*quantity);
      }
    },[quantity])

  return(
    <Fragment>
    <h2> Παραγγελία Ρόμαν-Ψευτορόμαν </h2>
    {errorMessage}
    <div>
    <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options.length>0 ? 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />:
            <FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
          }
        </span>
    </div>
    <div className={classes.textarea}>
    <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
    <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
    </div>
    
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Επιλέξτε: </label>
        <select id="type" name="type" onChange={selectTypeHandler} value={typeSelection}>
          <option value="Roman">Ρόμαν</option>
          <option value="Pseudoroman">Ψευτορόμαν</option>
        </select>
      </span>
    </div>
    <Input
    onChange={meterPriceFabricChangeHandler}
    label="Τιμή Μέτρου Ύφασμα:"
    vert="true"
    isPrice="true"
    input={{
      id: 1,
      type: 'text',
      value: meterPriceFabric
    }} />
    
    {typeSelection !== "Pseudoroman"&&
    <Fragment>
    <Input
    onChange={meterPriceMechanismChangeHandler}
    label="Τιμή Μέτρου Μηχανισμού:"
    vert="true"
    isPrice="true"
    input={{
      id: 1,
      type: 'text',
      value: meterPriceMechanism
    }} />
    <p style={{color:'orange'}}>Αφήστε κενό το πεδίο τιμής μηχανισμού, αν ο πελάτης έχει ήδη μηχανισμό</p>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Θέση χειριστηρίων: </label>
        <select id="type" name="type" onChange={mechanismSelectionHandler} value={mechanism}>
          <option value="right">Δεξιά</option>
          <option value="left">Αριστερά</option>
        </select>
      </span>
    </div>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Χρώμα Μηχανισμού: </label>
        <select id="type" name="type" onChange={mechanismColorSelectionHandler} value={mechanismColor}>
          <option value="leuko">Λευκό</option>
          <option value="inox">Inox</option>
        </select>
      </span>
    </div>
    </Fragment>
  }
    <Input
    onChange={windowWidthChangeHandler}
    onBlur={handleBlur}
    onFocus={handleFocus}
    label="Πλάτος Παραθύρου (m):"
    vert="true"
    input={{
      id: 1,
      name: 'windowWidth',
      type: 'text',
      value: windowWidth
    }} />
    <Input
    onChange={windowHeightChangeHandler}
    onBlur={handleBlur}
    onFocus={handleFocus}
    label="Ύψος Παραθύρου (m):"
    vert="true"
    input={{
      id: 1,
      name: 'windowHeight',
      type: 'text',
      value: windowHeight
    }} />
    
    
    { typeSelection !== "0" &&
    <Fragment>
    { typeSelection === "Roman" &&
      <Fragment>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Μέρος τοποθέτησης: </label>
          <select id="type" name="type" value={installPosition} onChange={installPositionHandler}>
            <option value="inside">Μέσα</option>
            <option value="outside">Έξω</option>
          </select>
        </span>
      </div>
      <div>
      <Input
      onChange={romanWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Ρόμαν (m):"
      vert="true"
      input={{
        id: 1,
        name: 'romanWidth',
        type: 'text',
        value: romanWidth
      }} />
      {parseFloat(windowWidth)>0&&<p className={classes.hint}>Προτεινόμενο πλάτος: {installPosition==="outside" ? Math.round((parseFloat(windowWidth) + 0.20)*100)/100 : Math.round((parseFloat(windowWidth) - 0.03)*100)/100} m</p>}
      </div>
      <div>
      <Input
      onChange={romanHeightChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Ολικό Ύψος Ρόμαν (m):"
      vert="true"
      input={{
        id: 1,
        name: 'romanHeight',
        type: 'text',
        value: romanHeight
      }} />
      {parseFloat(windowHeight)>0&&<p className={classes.hint}>Προτεινόμενο ολικό ύψος: {installPosition==="outside" ? Math.round((parseFloat(windowHeight) + 0.60)*100)/100 : Math.round((parseFloat(windowHeight) + 0.30)*100)/100} m</p>}
      <p className={classes.hint}>Είναι υπολογισμένο όταν έχει σηκωθεί να μην καλύπτεται το παράθυρο και όταν έχει κατέβει να περισσεύει μια πιέτα.</p>
      </div>
      <div>
      <Input
      onChange={fabricMetersChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Ύφασμα (m):"
      vert="true"
      input={{
        id: 1,
        name: 'fabricMeters',
        type:'text',
      value: fabricMeters
      }} />
      {parseFloat(romanWidth)>0&&<p className={classes.hint}> Προτεινόμενο ύφασμα: {Math.round((parseFloat(romanWidth) + 0.30)*100)/100} m</p>}
      </div>
      <div className={classes.searchBlock}>
        <span className={classes.rafiSelect}>
          <label htmlFor="type">Τύπος Ραφής</label>
          <select id="type" name="type" onChange={liningSelectionHandler} value={liningSelection}>
            <option value="without">Σταθερό ύφασμα - ΑΠΛΟ ΧΩΡΙΣ ΦΟΔΡΑ (+26€/m2)</option>
            <option value="with">Σταθερό Ύφασμα - ΑΠΛΟ ΜΕ ΦΟΔΡΑ (+32€/m2)</option>
            <option value="lepto-without">Λεπτό Ύφασμα - ΑΠΛΟ ΧΩΡΙΣ ΦΟΔΡΑ (+40€/m2)</option>
            <option value="lepto-with">Λεπτό Ύφασμα - ΑΠΛΟ ΜΕ ΦΟΔΡΑ (+48€/m2)</option>
            <option value="dyo-simple">Δύο λεπτά υφάσματα μαζί - ΑΠΛΟ (+50€/m2)</option>
            <option value="stathero-without">Σταθερό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΧΩΡΙΣ ΦΟΔΡΑ (+34€/m2)</option>
            <option value="stathero-with">Σταθερό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΜΕ ΦΟΔΡΑ (+38€/m2)</option>
            <option value="lepto-isio-without">Λεπτό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΧΩΡΙΣ ΦΟΔΡΑ (+48€/m2)</option>
            <option value="lepto-isio-with">Λεπτό ύφασμα - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm ΜΕ ΦΟΔΡΑ (+56€/m2)</option>
            <option value="dyo-isia">Δύο λεπτά υφάσματα μαζί - ΜΕ ΙΣΙΑ ΦΑΣΑ ΚΑΤΩ 10-15cm (+60€/m2)</option>
            <option value="custom">Άλλη ραφή</option>
          </select>
        </span>
      </div>
      </Fragment>
    }
    {["dyo-simple","stathero-without","stathero-with","lepto-isio-without","lepto-isio-with","dyo-isia"].includes(liningSelection)&&
    <Fragment>
    <div className={classes.textarea}>
    <label htmlFor="secondSku">Κωδικός/Περιγραφή δευτέρου υφάσματος: </label>
    <textarea id="secondSku" rows="2" value={secondSku} onChange={secondSkuChangeHandler}></textarea>
    </div>
    <Input
    onChange={secondMeterPriceFabricChangeHandler}
    label="Τιμή Μέτρου Δευτέρου Υφάσματος:"
    vert="true"
    isPrice="true"
    input={{
      id: 1,
      type: 'text',
      value: secondMeterPriceFabric
    }} />
    </Fragment>
    }
    {liningSelection === "custom" && 
      <Fragment>
        <div className={classes.searchBlock}>
          <span>
            <label htmlFor="type">Φόδρα</label>
            <select id="type" name="type" onChange={fodraHandler} value={fodra}>
              <option value="oxi">Χωρίς Φόδρα</option>
              <option value="nai">Με φόδρα</option>
            </select>
          </span>
        </div>
        <Input
          onChange={timiRafisChangeHandler}
          label="Τιμή Ραφής:"
          vert="true"
          input={{
            id: 1,
            type: 'text',
          value: timiRafis
          }} />
      </Fragment>}
    { typeSelection === "Pseudoroman" &&
      <Fragment>
      <Input
      onChange={rodWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Μήκος κουρτινόξυλου (m):"
      vert="true"
      input={{
        id: 1,
        name: 'rodWidth',
        type: 'text',
      value: rodWidth
      }} />
      <div>
      <Input
      onChange={fabricMetersChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Ύφασμα μέτρα (m):"
      vert="true"
      input={{
        id: 1,
        name: 'fabricMeters',
        type: 'text',
      value: fabricMeters
      }} />
      {parseFloat(rodWidth)>0&&<p className={classes.hint}> Προτεινόμενα μέτρα: {Math.round((parseFloat(rodWidth) + 0.30)*100)/100} m</p>}
      </div>
      <div>
      <Input
      onChange={romanWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Μήκος Ψευτορόμαν (m):"
      vert="true"
      input={{
        id: 1,
        name: 'romanWidth',
        type: 'text',
      value: romanWidth
      }} />
      <p className={classes.hint}>Από γατζάκι έως εκεί που θέλουμε να τελειώνει</p>
      </div>
      <div>
      <Input
      onChange={pleatsChangeHandler}
      label="Πόσες πιέτες να έχει;:"
      vert="true"
      input={{
        id: 1,
        type: 'text',
      value: pleats
      }} />
      <p className={classes.hint}>Προτεινόμενος αριθμός πιετών = 2 </p>
      </div>
      <span className={classes.secondaryGrid}><p> Διαστάσεις Ψευτορόμαν: {dimensions} </p>
      <Button className={classes.btnStyle} onClick={dimensionsCalculate}>Υπολογισμός</Button></span>
      </Fragment>
    }
    {(typeSelection === "Pseudoroman" || liningSelection !== "0") &&
    <Fragment>
    <p>{totalPrice>0 && 'Τιμή: '+totalPrice+'€'}</p>

    {totalPrice>0 && <div>
    <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
    {!discount && <p></p>}
    {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      vert="true"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>}
    </div>}

    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="installation">Τοποθέτηση: </label>
        <select id="installation" name="installation" onChange={installationHandler} value={installation}>
          <option value="oxi">Όχι</option>
          <option value="nai">Ναι</option>    
        </select>
      </span>
    </div>
    { installation ==="nai" &&
    <Input
    onChange={installationPriceChangeHandler}
    label="Κόστος:"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: installPrice
    }} />}
        </Fragment>}
        </Fragment>}
    <div className={classes.textarea}>
    <label htmlFor="sku">Σημειώσεις: </label>
    <textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
    </div>
    <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      vert="true"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
    </div>

    <div className={classes.actions}>
      <Button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</Button>
        <Button className={`${classes[`button--alt`]} ${buttonDisabled? classes.buttonLoad:''}`} disabled={buttonDisabled} onClick={handleSaveClick} >{buttonDisabled?<FontAwesomeIcon icon={faCircleNotch} spin />:'Αποθήκευση'}</Button>
    </div>
    </Fragment>
  )
}

export default RomanPseudoroman;

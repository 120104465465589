import React,{Fragment, useState, useEffect, useRef} from 'react';

import {useCookies} from 'react-cookie';
import { Link, Navigate, useNavigate, useLocation} from "react-router-dom";
import Input from '../UI/Input'
import Button from '../UI/Button'
import classes from './NewOrderTasksEmployee.module.css';
import axios from 'axios';

const NewOrderTasksEmployee = (props) => {
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const navigate = useNavigate();

  

  const [paymentSelection, setPaymentSelection] = useState('metrita');

  const [clientCookie, setClientCookie,removeClientCookie] = useCookies (['client']);
  const [errorMessage, setErrorMessage] =useState('');

  const location = useLocation();
  const textareaRef = useRef(null);
  const { totalCost } = location.state;
  const { installPrice } = location.state;
  const { initPrice } = location.state;
  const { deliver } = location.state;
  const { measure } = location.state;
  const { sew } = location.state;
  const { iron } = location.state;
  const { note } = location.state;
  const { advancePay } = location.state;

  const [paradosi,setParadosi] = useState(deliver);
  const [metrisi,setMetrisi] = useState(measure);
  const [rafis,setRafis] = useState(sew);
  const [sideroma,setSideroma] = useState(iron);
  const [topothetisi,setTopothetisi] = useState(installPrice);
  const [synolo,setSynolo] = useState(totalCost);
  const [initialPrice, setInitialPrice] = useState(initPrice);
  const [notes, setNotes]=useState(note);
  const [prokatavoli,setProkatavoli] = useState(advancePay||0);

  const [orderExtras, setOrderExtras] = useState('');
  const [orderPayment, setOrderPayment] = useState('');


  const basePrice = initPrice - (installPrice||0) - (deliver||0) - (measure||0) - (sew||0) - (iron||0);
  const baseSynolo = totalCost - (installPrice||0) - (deliver||0) - (measure||0) - (sew||0) - (iron||0);

useEffect (()=>{
  const config = {
        headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/'+cookies.OrderId,{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setOrderExtras(JSON.parse(response.data.extras));
      setOrderPayment(JSON.parse(response.data.payment));
      console.log(JSON.parse(response.data.payment).initialPrice)
    })
    .catch(function (error) {
      console.log(error);
    });

// }
},[]);

useEffect(()=>{
  console.log(orderExtras);
  if (orderExtras.paradosi){
    setParadosi(orderExtras.paradosi);
  }
  if (orderExtras.paradosi){
    setMetrisi(orderExtras.metrisi);
  }

  if (orderExtras.paradosi){
    setRafis(orderExtras.rafis);
  }
  if (orderExtras.paradosi){
    setSideroma(orderExtras.sideroma);
  }
},[orderExtras])
  

  const paradosiChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setParadosi(value);
  }
  const metrisiChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setMetrisi(value);
  }
    const topothetisiChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setTopothetisi(value);
  }
    const rafisChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setRafis(value);
  }
    const sideromaChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setSideroma(value);
  }
  // const initialPriceChangeHandler = (event)=>{
  //   let value = event.target.value.toString();
  //   value = value.replace(/,/g,".");
  //   setInitialPrice(value);
  // }
  const synoloChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setSynolo(value);
  }
  const prokatavoliChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setProkatavoli(value);
  }
  const paymentSelectionHandler = (event)=>{
    setPaymentSelection(event.target.value);

  }
  const notesChangeHandler = (event) =>{
    setNotes(event.target.value);
  }

  useEffect(()=>{
    console.log(Math.round((Number(baseSynolo)+Number(paradosi||0)+Number(metrisi||0)+Number(topothetisi||0)+Number(rafis||0)+Number(sideroma||0))*100)/100)
    setSynolo(Math.round((baseSynolo+Number(paradosi||0)+Number(metrisi||0)+Number(topothetisi||0)+Number(rafis||0)+Number(sideroma||0))*100)/100)
    setInitialPrice(Math.round((basePrice+Number(paradosi||0)+Number(metrisi||0)+Number(topothetisi||0)+Number(rafis||0)+Number(sideroma||0))*100)/100)
    
  },[paradosi,metrisi,topothetisi,rafis,sideroma])

  useEffect(()=>{

    const config = {
          headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    axios.post('https://api.orders.e-interior.gr/api/order/'+cookies.OrderId,{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'
      },
      config
    )
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  },[])

  const handleSaveClick = (e) =>{
    if ( synolo != ''){
      const config = {
        headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
      };
      let currDate = new Date();
      const detailed = JSON.stringify([
        {type:"prokatavoli",
        amount:prokatavoli, 
        how:paymentSelection,
        date: currDate
        }
      ])
      const extras = JSON.stringify({
        paradosi: paradosi,
        metrisi: metrisi,
        topothetisi: topothetisi,
        rafis: rafis,
        sideroma: sideroma,
        notes:notes
      })
      const payment = JSON.stringify({
        initialPrice:initialPrice,
        synolo:synolo,
        detailed:detailed
      })
      axios.post('https://api.orders.e-interior.gr/api/order/update',{
            key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
            order_id: cookies.OrderId,
            extras:extras,
            payment:payment,
            },
            config
          )
          .then(function (response) {
            console.log(response.data);

          })
          .catch(function (error) {
            console.log(error);
          });
    }else{
      e.preventDefault();
      setErrorMessage('Missing required fields');
    }
  }

  useEffect(() => {
      if (textareaRef.current) {
        // Reset the height to auto to calculate the new height correctly
        textareaRef.current.style.height = 'auto';
        // Set the height to the scrollHeight to expand the textarea
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [notes]);
  return(
    <Fragment>
    {errorMessage}
    <div className={classes.serviceCost}>
      <span className={classes.dividerTitle}><strong>Επιπλέον Υπηρεσίες/Κόστος: </strong></span>
      <span className={classes.divider}></span>
    </div>

    <div className={classes.serviceForm}>
    <p>
      <Input onChange={paradosiChangeHandler} 
      label="Κατ'οίκον παράδοση:"
      vert="true"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: paradosi
      }} />
      </p>
      <p>
      <Input onChange={metrisiChangeHandler}
      label="Κατ'οίκον μέτρηση:"
      vert="true"
      isPrice="true"
      input={{
        id: 2,
        type: 'text',
        value: metrisi
      }} />
      </p>
      <p>
      <Input onChange={topothetisiChangeHandler}
      label="Κόστος Τοποθέτησης/Εργασίας:"
      vert="true"
      isPrice="true"
      input={{
        id: 3,
        type: 'text',
        value: topothetisi
      }} />
      </p>
      <p>
      <Input onChange={rafisChangeHandler}
      label="Κόστος ραφής:"
      vert="true"
      isPrice="true"
      input={{
        id: 4,
        type: 'text',
        value: rafis
      }} />
      </p>
      <p>
      <Input onChange={sideromaChangeHandler}
      label="Σιδέρωμα Κουρτίνας (2,50€/μ):"
      vert="true"
      isPrice="true"
      input={{
        id: 5,
        type: 'text',
        value: sideroma
      }} />
      </p>
    </div>
    <div className={classes.paymentTypeHeader}>
      <span className={classes.dividerTitle}><strong>Τρόπος Πληρωμής: </strong></span>
      <span className={classes.divider}></span>
    </div>
    <div className={classes.paymentForm}>
      <p>
        <select id="paymentSelection" name="paymentSelection" onChange={paymentSelectionHandler}>
          <option value="metrita">Μετρητά</option>
          <option value="karta">Κάρτα</option>
          <option value="katathesi">Κατάθεση</option>
        </select>
      </p>
      <p>
       Αρχική Τιμή: {initialPrice}€
      </p>
      <p>
      <Input onChange={synoloChangeHandler}
      label="Σύνολο:"
      vert="true"
      isPrice="true"
      input={{
        id: 9,
        type:'text',
        value: synolo
      }} />
      </p>
      <p>
      <Input onChange={prokatavoliChangeHandler}
      label="Προκαταβολή:"
      vert="true"
      isPrice="true"
      input={{
        id: 10,
        type:'text',
        value: prokatavoli
      }} />
      </p>
    <p>
      Υπόλοιπο: {Math.round((synolo-prokatavoli)*100)/100}€
    </p>

    <div className={classes.textarea}>
        <label htmlFor="sku"><p>Σημείωση Παραγγελίας: </p></label>
        <textarea id="sku" rows="2" value={notes} ref={textareaRef} onChange={notesChangeHandler} style={{ overflow: 'hidden' }}></textarea>
    </div>
    </div>
    <div className={`${classes.ButtonSet}`}>
      <span></span>
      <Link to="../1">
      <span className={classes.cancelButton}><Button>Πίσω</Button></span>
      </Link>
      <span></span>
      <Link to="../3">
      <span className={classes.nextButton} onClick={handleSaveClick}><Button>Αποθήκευση</Button></span>
      </Link>
      <span></span>
    </div>
    </Fragment>
  )
}

export default NewOrderTasksEmployee;
